<template>
  <v-app-bar app class="boxShawdom navbar-class">
    <v-overlay :value="getOver"></v-overlay>
    <v-app-bar-nav-icon @click="setDraw"></v-app-bar-nav-icon>
    <v-spacer></v-spacer>
      <v-avatar>
        <img src="../../assets/images/user.png" alt="Photo user"/>
      </v-avatar>
      <div    @mouseover="menu_header = true" style="cursor: pointer">
        <p class="mb-1 mt-5 mx-5" style="font-size: 14px; font-weight: bold">
          {{ getUser.full_name ? getUser.full_name : '' }}
        </p>
        <p class="mt-1" style="font-size: 12px; text-align: center">
          {{ getUser.UsersRole.name ? getUser.UsersRole.name : '' }}
        </p>
      <div v-if="menu_header" style="position: absolute;background: white; right: 60px; ">
        <v-card
            @mouseleave="menu_header = false"
            class="mx-auto"
            max-width="100%"
        >
          <v-list>
            <v-list-item-group v-model="model">
              <v-list-item
                  v-for="(item, i) in items"
                  :key="i"
                  :to="{name: item.href}"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </div>
    </div>
    <v-btn icon @click="logout">
      <v-icon>mdi-export</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "Navbar",
  data() {
    return {
      menu_header:false,
      items: [
        {
          icon: 'far fa-user',
          text: 'Perfil',
          href: 'Profile'
        },
        {
          icon: 'fas fa-info',
          text: 'Informacion',
          href: 'Information'
        },
      ],
      model: 1,
    }
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
    ...mapGetters(['getOver'])
  },
  methods: {
    ...mapActions(['setDraw',]),
    ...mapActions('auth', ['logout',]),
  },
  created() {
  }
};
</script>

<style lang="scss">
.navbar-class {
  background: var(--v-background-base) !important;
  height: 100px !important;
}

.v-toolbar__content, .v-toolbar__extension {
  margin-top: 10px !important;
}
</style>

<template>
  <img v-if="!getDark" src="../../assets/images/logo-white.png" :width="width">
  <img v-else src="../../assets/images/logo.png" :width="width">
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Logo",
  data(){
    return{
      dark:false
    }
  },
  props:{
    width: String,
  },

  computed:{
    ...mapGetters('general', ['getDark'])
  }

}
</script>

<style scoped>

</style>

<template>
  <v-navigation-drawer width="280" :value="getDraw" class="boxShawdom" app>
    <v-overlay :value="getOver"></v-overlay>

    <v-list
        class="boxShawdom d-flex align-center"
        style="height: 100px; width: 280px"
    >
      <v-list-item class="d-flex justify-center">
        <v-list-item-icon>
          <Logo width="200px"/>
        </v-list-item-icon>
      </v-list-item>
    </v-list>

    <v-list class="dash" style="margin-top: 40px; ">
      <router-link :to="`/`" class="link" >
        <v-list-group
            :value="true"
            prepend-icon="fas fa-home"
            class="primary--text"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title
                  class="secondary--text"

              >Dashboard
              </v-list-item-title
              >
            </v-list-item-content>
          </template>
        </v-list-group>
      </router-link>
    </v-list>
    <v-list class="dash">
      <router-link :to="{name:'ListClients'}" class="link">
        <v-list-group
            :value="true"
            prepend-icon="fas fa-users"
            class="primary--text"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title
                  class="secondary--text"

              >Clientes
              </v-list-item-title
              >
            </v-list-item-content>
          </template>
        </v-list-group>
      </router-link>
    </v-list>
    <v-list class="dash">
      <router-link :to="{name:'ListProducts'}" class="link">
        <v-list-group
            :value="true"
            prepend-icon="fas fa-boxes"
            class="primary--text"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title
                  class="secondary--text"

              >Productos
              </v-list-item-title
              >
            </v-list-item-content>
          </template>
        </v-list-group>
      </router-link>
    </v-list>
    <v-list class="dash" >
      <router-link :to="{name:'ListInvoice'}"  class="link">
        <v-list-group
            :value="true"
            prepend-icon="fas fa-file-pdf"
            class="primary--text"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title
                  class="secondary--text"

              >Facturas
              </v-list-item-title
              >
            </v-list-item-content>
          </template>
        </v-list-group>
      </router-link>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {mapGetters} from "vuex";
import Logo from "../General/Logo";

export default {
  name: "SideBar",
  data() {
    return {
      dark: false
    }
  },
  computed: {
    ...mapGetters(['getDraw', 'getOver'])
  },
  methods: {},
  components: {
    Logo
  }
};
</script>

<style lang="scss" scoped>
.link {
  text-decoration: none !important;
}
.primary--text {
  font-size: 30px !important;
  color: var(--v-text_menu-base) !important;
}
.secondary--text {
  font-size: 17px !important;
  font-weight: 500;
}
</style>
